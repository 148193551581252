import './Section.sass';

function Section({ label, children }) {

	return (
		<section>
			<div className="label">{label}</div>
			<div className="content">{children}</div>
		</section>
	);
}

function SectionRow({ children }) {
	return ( <div className="section-row">{children}</div> );
}

function SectionRowList({ children }) {
	return ( <ul className="section-row-list">{children}</ul> );
}

function SectionRowItem({ children }) {
	return ( <li className="section-row-item">{children}</li> );
}

export {
	Section,
	SectionRow,
	SectionRowList,
	SectionRowItem,
};